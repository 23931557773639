import { Organisation } from '../../great-grey-lib'
import { Project } from '../Project/Project'
import { DataSetRow } from './DataSet'

export enum DataPointStatus {
  Unassigned = 'Unassigned',
  Assigned = 'Assigned',
  Ignored = 'Ignored'
}

export interface DataPoint {
  id: string
  createdAt: Date
  updatedAt: Date
  organisation: Organisation
  source: string
  project: Project
  row: DataSetRow
  status: DataPointStatus
  predictionData?: any
}
