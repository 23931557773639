import { TaskType, TaskData } from '../Task/TaskData'
import { DataSetRow } from '../DataSet/DataSet'
import { Organisation } from '../Organisation/Organisation'
import { Job } from '../Job/Job'
import { Project } from '../Project/Project'

export enum SubTaskStatus {
  Unstarted = 'Unstarted',
  In_Progress = 'In_Progress',
  Completed = 'Completed'
}

export interface SubTaskRows {
  [rowId: string]: DataSetRow
}

export interface SubTask {
  id: string
  createdAt: Date
  updatedAt: Date
  organisation: Organisation
  type: TaskType
  status: SubTaskStatus
  task: TaskData
  redundancy: number
  minimumConsensus: number
  rowCount: number
  availableAssignments: number
  data: SubTaskRows
  dataOrder: Array<string>
  job: Job
  projectId: string
  project?: Project
}
