import { Intent, EntityDefinition, SentimentScoreType } from '../NLP/NLP'
import { UnreachableCaseError } from '../../helpers/UnreachableCaseError'
import { BoundingBoxType, DataSetColumnType } from '../DataSet/DataSet'
import { ShortId } from '../../helpers/UID'

export interface Category {
  id: string
  name: string
}

export interface TaskCategory extends Category {
  include?: boolean
  specific?: TaskCategory[]
  specificTitle?: string
}
/* This is used and cleaned in the entity task creation step. */
export interface TaskEntity extends EntityDefinition {
  on: boolean
}

export enum TaskType {
  Split = 'Split',
  Intent = 'Intent',
  Entity = 'Entity',
  Sentiment = 'Sentiment',
  TextCategorize = 'Text Categorize',
  AudioCategorize = 'Audio Categorize',
  AudioSplit = 'Audio Split',
  Transcribe = 'Transcribe',
  ImageCategorize = 'Image Categorize',
  ImageBoundingBox = 'Image Bounding Box'
}

export enum TaskStatus {
  Unstarted = 'Unstarted',
  In_Progress = 'In Progress',
  Complete = 'Complete'
}

export interface TaskDataInterface {
  name: string
  type: TaskType
  status: TaskStatus
  subTaskSize: number
  redundancy: number
  groupByParentId?: boolean
  instructionsId?: string
  taskHeading?: string
}

export interface TaskData_Split extends TaskDataInterface {
  type: TaskType.Split
}

export interface TaskData_Intent extends TaskDataInterface {
  type: TaskType.Intent
  intents: Array<Intent>
}
export interface TaskData_Entity extends TaskDataInterface {
  type: TaskType.Entity
  entities: TaskEntity[]
}

export interface TaskData_TextCategorize extends TaskDataInterface {
  type: TaskType.TextCategorize
  splitByColumn: true
  outputColumnName?: string
  categories: TaskCategory[]
  possibileCategories?: TaskCategory[] // This is removed on save of the job.
}

export interface TaskData_Sentiment extends TaskDataInterface {
  type: TaskType.Sentiment
  groupByParentId: true
}

export interface TaskData_ImageCategorize extends TaskDataInterface {
  type: TaskType.ImageCategorize
  categoryName: string
  categories: TaskCategory[]
  possibileCategories?: TaskCategory[] // This is removed on save of the job.
}

export interface TaskData_ImageBoundingBox extends TaskDataInterface {
  type: TaskType.ImageBoundingBox
  boundingBoxType: BoundingBoxType
  categoryId?: string
}

export interface TaskData_AudioCategorize extends TaskDataInterface {
  type: TaskType.AudioCategorize
  categories: TaskCategory[]
}

export interface TaskData_AudioSplit extends TaskDataInterface {
  type: TaskType.AudioSplit
}
export interface TaskData_Transcribe extends TaskDataInterface {
  type: TaskType.Transcribe
}

export function MeetsTaskRequirments(type: TaskType, columnTypes: DataSetColumnType[]): boolean {
  switch (type) {
    case TaskType.Split:
    case TaskType.Intent:
    case TaskType.Entity:
    case TaskType.Sentiment:
    case TaskType.TextCategorize:
      return columnTypes.includes(DataSetColumnType.Text)
    case TaskType.AudioCategorize:
    case TaskType.Transcribe:
      return columnTypes.includes(DataSetColumnType.AudioURL)

    case TaskType.ImageCategorize:
      return columnTypes.includes(DataSetColumnType.ImageURL)

    case TaskType.ImageBoundingBox:
      return (
        columnTypes.includes(DataSetColumnType.ImageURL) &&
        columnTypes.includes(DataSetColumnType.Categories)
      )
    case TaskType.AudioSplit:
      return (
        columnTypes.includes(DataSetColumnType.AudioURL) &&
        columnTypes.includes(DataSetColumnType.AudioCategories)
      )
    default:
      throw new UnreachableCaseError(type)
  }
}

export function EmptyTaskData(type: TaskType): TaskData {
  switch (type) {
    case TaskType.Split:
      return {
        name: 'Split Task',
        type: TaskType.Split,
        subTaskSize: 45,
        redundancy: 3,
        status: TaskStatus.Unstarted
      }
    case TaskType.Intent:
      return {
        name: 'Intent Task',
        type: TaskType.Intent,
        subTaskSize: 45,
        redundancy: 3,
        status: TaskStatus.Unstarted,
        intents: []
      }
    case TaskType.Entity:
      return {
        name: 'Entity Task',
        type: TaskType.Entity,
        subTaskSize: 45,
        redundancy: 3,
        status: TaskStatus.Unstarted,
        entities: []
      }
    case TaskType.Sentiment:
      return {
        name: 'Sentiment Task',
        type: TaskType.Sentiment,
        subTaskSize: 45,
        redundancy: 2,
        groupByParentId: true,
        status: TaskStatus.Unstarted
      }
    case TaskType.TextCategorize:
      return {
        name: 'Text Categorize',
        type: TaskType.TextCategorize,
        subTaskSize: 45,
        redundancy: 2,
        splitByColumn: true,
        status: TaskStatus.Unstarted,
        categories: []
      }
    case TaskType.ImageCategorize:
      return {
        name: 'Image Categorize Task',
        type: TaskType.ImageCategorize,
        subTaskSize: 30,
        redundancy: 2,
        status: TaskStatus.Unstarted,
        categoryName: 'Category',
        categories: [],
        possibileCategories: [
          { id: ShortId(), name: 'Yes', include: true },
          { id: ShortId(), name: 'No' }
        ]
      }
    case TaskType.ImageBoundingBox:
      return {
        name: 'Image Bounding Box Task',
        type: TaskType.ImageBoundingBox,
        subTaskSize: 30,
        redundancy: 1,
        boundingBoxType: BoundingBoxType.Rectangle,
        status: TaskStatus.Unstarted,
        categoryId: undefined,
        taskHeading: 'Draw a box around every #*categoryId*#'
      }
    case TaskType.AudioCategorize:
      return {
        name: 'Audio Categorize Task',
        type: TaskType.AudioCategorize,
        subTaskSize: 10,
        redundancy: 1,
        status: TaskStatus.Unstarted,
        categories: [
          { id: ShortId(), name: 'Agent' },
          { id: ShortId(), name: 'Customer' }
        ]
      }
    case TaskType.AudioSplit:
      return {
        name: 'AudioSplit Task',
        type: TaskType.AudioSplit,
        subTaskSize: 10,
        redundancy: 1,
        status: TaskStatus.Unstarted
      }
    case TaskType.Transcribe:
      return {
        name: 'Transcribe Task',
        type: TaskType.Transcribe,
        subTaskSize: 30,
        redundancy: 2,
        status: TaskStatus.Unstarted
      }

    default:
      throw new UnreachableCaseError(type)
  }
}

export type TaskData =
  | TaskData_Split
  | TaskData_Intent
  | TaskData_Entity
  | TaskData_Sentiment
  | TaskData_TextCategorize
  | TaskData_ImageCategorize
  | TaskData_ImageBoundingBox
  | TaskData_AudioCategorize
  | TaskData_AudioSplit
  | TaskData_Transcribe
