export function RandomToken() {
 function s4() {
   return Math.floor((1 + Math.random()) * 0x10000)
     .toString(16)
     .substring(1);
 }
 var data =  s4() + s4() + '-' + s4() + '-' + s4() + '-' +
   s4() + '-' + s4() + s4() + s4();
   return data;
}

export function ShortId() {
  function s4() { return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1); };
  const data =  s4() + s4();
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // We make sure the ID starts with a letter. That way we ca use them as valid IDs.
  possible = possible.charAt(Math.floor(Math.random() * possible.length));
  return possible + data;
}
