import { Entity, Intent } from '../NLP/NLP'
import { BoundingBox, TimelineCategory } from '../DataSet/DataSet'
import { Category } from '../Task/TaskData'

export enum ChangeStatus {
  SystemSelected = 'SystemSelected',
  SystemSelectedUserChanged = 'SystemSelectedUserChanged',
  UnresolvedEscalation = 'UnresolvedEscalation',
  ResolvedEscalation = 'ResolvedEscalation'
}
export enum RowChangeType {
  Skipped = 'Skipped',
  Unchanged = 'Unchanged',
  Excluded = 'Excluded',
  Intent = 'Intent',
  Entity = 'Entity',
  TextCategorize = 'TextCategorize',
  AudioCategorize = 'AudioCategorize',
  AudioSplit = 'AudioSplit',
  Transcribe = 'Transcribe',
  Sentiment = 'Sentiment',
  ImageCategorize = 'ImageCategorize',
  ImageBoundingBox = 'ImageBoundingBox'
}

export interface RowChange_Skipped {
  type: RowChangeType.Skipped
}
export function CreateRowChange_Skipped(): RowChange_Skipped {
  return { type: RowChangeType.Skipped }
}

export interface RowChange_Unchanged {
  type: RowChangeType.Unchanged
}
export function CreateRowChange_Unchanged(): RowChange_Unchanged {
  return { type: RowChangeType.Unchanged }
}

export interface RowChange_Excluded {
  type: RowChangeType.Excluded
}
export function CreateRowChange_Excluded(): RowChange_Excluded {
  return { type: RowChangeType.Excluded }
}

export interface RowChange_Intent {
  type: RowChangeType.Intent
  intent: Intent
}
export function CreateRowChange_Intent(intent: Intent): RowChange_Intent {
  return { type: RowChangeType.Intent, intent: intent }
}

export interface RowChange_Entity {
  type: RowChangeType.Entity
  entities: Entity[]
}
export function CreateRowChange_Entity(entities: Entity[]): RowChange_Entity {
  return { type: RowChangeType.Entity, entities: entities }
}

export interface RowChange_TextCategorize {
  type: RowChangeType.TextCategorize
  categories: Category[]
}

export function CreateRowChange_TextCategorize(categories: Category[]): RowChange_TextCategorize {
  return { type: RowChangeType.TextCategorize, categories: categories }
}

export interface RowChange_ImageCategorize {
  type: RowChangeType.ImageCategorize
  category: Category
}

export function CreateRowChange_ImageCategorize(category: Category): RowChange_ImageCategorize {
  return { type: RowChangeType.ImageCategorize, category: category }
}

export interface RowChange_ImageBoundingBox {
  type: RowChangeType.ImageBoundingBox
  boundingBoxes: BoundingBox[]
}

export function CreateRowChange_ImageBoundingBox(
  boundingBoxes: BoundingBox[]
): RowChange_ImageBoundingBox {
  return { type: RowChangeType.ImageBoundingBox, boundingBoxes: boundingBoxes }
}

export interface RowChange_AudioCategorize {
  type: RowChangeType.AudioCategorize
  categories: TimelineCategory[]
}
export function CreateRowChange_AudioCategorize(
  categories: TimelineCategory[]
): RowChange_AudioCategorize {
  return { type: RowChangeType.AudioCategorize, categories: categories }
}

export interface RowChange_AudioSplit {
  type: RowChangeType.AudioSplit
  categories: TimelineCategory[]
}
export function CreateRowChange_AudioSplit(categories: TimelineCategory[]): RowChange_AudioSplit {
  return { type: RowChangeType.AudioSplit, categories: categories }
}

export interface RowChange_Transcribe {
  type: RowChangeType.Transcribe
  text: string
}
export function CreateRowChange_Transcribe(text: string): RowChange_Transcribe {
  return { type: RowChangeType.Transcribe, text: text }
}

export interface RowChange_Sentiment {
  type: RowChangeType.Sentiment
  sentiment: number
}
export function CreateRowChange_Sentiment(sentiment: number): RowChange_Sentiment {
  return { type: RowChangeType.Sentiment, sentiment: sentiment }
}

export type RowChange =
  | RowChange_Skipped
  | RowChange_Unchanged
  | RowChange_Excluded
  | RowChange_Intent
  | RowChange_Entity
  | RowChange_TextCategorize
  | RowChange_AudioCategorize
  | RowChange_ImageCategorize
  | RowChange_ImageBoundingBox
  | RowChange_AudioSplit
  | RowChange_Transcribe
  | RowChange_Sentiment
