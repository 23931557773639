import { Organisation } from '../Organisation/Organisation'
import { Project, ProjectMembershipType } from '../Project/Project'
import { User } from '../User/User'

export enum InviteStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Revoked = 'revoked',
  Rejected = 'rejected'
}

export enum InviteType {
  Organisation = 'organisation',
  Project = 'project'
}

export interface Invite {
  id: string
  createdAt?: Date
  updatedAt?: Date
  inviteeEmail?: string
  inviteeUserId?: string
  invitedByUserId?: string
  invitedByUser?: User
  status: InviteStatus
  type: InviteType
  projectId?: string
  project?: Project
  projectMembershipType?: ProjectMembershipType
  organisationId: string
  organistation?: Organisation
}
