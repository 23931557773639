import debug from 'debug';

const CreateLogger =  function(scope: string) {
  var log   = debug(scope) as any;
  log.error = debug('error');
  log.warn  = debug('warn');
  log.test  = debug('test')
  return log;
};
export {CreateLogger};
