import { CreateLogger } from '../../helpers/CreateLogger'
import {
  TaskType,
  DataSetRow,
  ChangeStatus,
  RowChange,
  AssignmentWork,
  AssignmentWorkRow,
  Job,
  Project
} from '../../great-grey-lib'
const log = CreateLogger('job:interface')

export interface JobReview {
  id: string
  jobId: string
  organisationId: string
  projectId: string
  project?: Project
  rows: ReviewRow[]
}

export interface ReviewRow {
  type: TaskType
  originalRow: DataSetRow
  work: AssignmentWorkRow[]
  confidence: number
  changeStatus: ChangeStatus
  change: RowChange
}
