import { Intent, EntityDefinition, Utterance } from '../NLP/NLP'
import { User } from '../User/User'
import { Organisation } from '../Organisation/Organisation'
import { Project } from '../Project/Project'
import { TaskCategory } from '../Task/TaskData'
export interface MLModel {
  id: string
  createdAt: Date
  updatedAt: Date
  organisation: Organisation
  name: string
  createdBy: User
  type: MLModelType
  data: MLModelData
  projectId: string
  project?: Project
}

export interface MLModelData {
  intents?: Array<Intent>
  entities?: Array<EntityDefinition>
  utterances?: Array<Utterance>
  categories?: TaskCategory[]
}

export function isMLModel(item: any, skipId?: boolean): item is MLModel {
  if (!skipId && item === undefined && (item as MLModel).id === undefined) {
    return false
  }
  return true
}

export enum MLModelType {
  JSON = 'JSON',
  LUIS = 'LUIS'
}

export function MLModelTypeForData(data: any): MLModelType {
  if (data && data.luis_schema_version) {
    return MLModelType.LUIS
  }
  return MLModelType.JSON
}

export function isMLModelData(item: any): item is MLModelData {
  let hasValidFields = false
  if ((item as MLModelData).intents && Array.isArray((item as MLModelData).intents)) {
    return true
  }
  if ((item as MLModelData).entities && Array.isArray((item as MLModelData).entities)) {
    return true
  }
  if ((item as MLModelData).utterances && Array.isArray((item as MLModelData).utterances)) {
    return true
  }
  if ((item as MLModelData).categories && Array.isArray((item as MLModelData).categories)) {
    return true
  }
  return hasValidFields
}

// export function isMLModelData(item: any): item is MLModelData {
//   return isNLPMLModelData(item)
// }

// export type MLModelData = NLPMLModelData
