import { TaskType, TaskData, TaskData_Intent, TaskData_Entity } from '../Task/TaskData'
import { DataSet, DataSetColumnType } from '../DataSet/DataSet'
import { UnreachableCaseError } from '../../helpers/UnreachableCaseError'
import { CreateLogger } from '../../helpers/CreateLogger'
import { User } from '../User/User'
import { Organisation } from '../Organisation/Organisation'
import { Project } from '../Project/Project'
const log = CreateLogger('job:interface')

export interface Job {
  id: string
  name: string
  createdBy: User

  organisation: Organisation
  projectId: string
  project?: Project
  dataSet?: DataSet
  jobDataSet?: DataSet
  outputDataSet?: DataSet
  status: JobStatus
  createdAt: Date
  updatedAt: Date
  tasks: JobTaskData
  models: JobModelData
  rowCount: number
  dataFileName?: string
  unresolvEdescalations?: number
  jobReviewId?: string
}

export function isJob(item: any, skipId?: boolean): item is Job {
  if (!skipId && item === undefined && (item as Job).id === undefined) {
    return false
  }
  return (item as Job).name !== undefined && (item as Job).createdBy !== undefined
}

export enum JobStatus {
  Unstarted = 'Unstarted',
  Pending_Start = 'Pending Start',
  In_Progress = 'In Progress',
  Ready_For_Review = 'Ready For Review',
  Completed = 'Completed'
}

export function TaskOrder(columnTypes: DataSetColumnType[]): Array<Array<TaskType>> {
  return [
    [TaskType.AudioCategorize],
    [TaskType.AudioSplit],
    [TaskType.ImageCategorize],
    [TaskType.ImageBoundingBox],
    [TaskType.Split],
    [TaskType.Transcribe],
    [TaskType.Intent, TaskType.Entity, TaskType.Sentiment, TaskType.TextCategorize]
  ]
}

export type JobTaskData = {
  [type: string]: TaskData
}
export type JobModelData = {
  [id: string]: string // Id and name of the models used to pull the data in to the job.
}

/**
 * Test to see if a job is valid. Used before and during save.
 * @param job
 */
export function isJobStartable(job: Job): boolean {
  // if (!job.type) {
  //   log('No Job Type')
  //   return false
  // }

  let dataSet = job.dataSet
  if (!dataSet) {
    log('no dataSet')
    return false
  }
  return true // Will need to come back and add some logic here checking column types and task types.
}
