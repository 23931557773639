export interface Intent {
  id?: string
  name: string
}

export interface EntityDefinition {
  id?: string
  name: string
}

export interface Entity extends EntityDefinition {
  startChar: number
  endChar: number
}

export interface Utterance {
  text: string
  intent: string
  entities: Array<Entity>
}

export type Entities = Array<Entity>

export enum SentimentScoreType {
  Scalar = 'Scalar',
  Binary = 'Binary'
}
