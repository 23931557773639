import { TaskType, TaskData } from '../Task/TaskData'
import { DataSetRow } from '../DataSet/DataSet'
import { Organisation, Job, SubTask, Project } from '../../great-grey-lib'
import { User } from '../User/User'
import { RowChange } from '../RowChange/RowChange'

export enum AssignmentStatus {
  In_Progress = 'In_Progress',
  Abandoned = 'Abandoned',
  Completed = 'Completed'
}

export enum WorkRowStatus {
  Unstarted = 'Unstarted',
  Skipped = 'Skipped',
  Completed = 'Completed'
}

export interface Assignment {
  id: string
  createdAt: Date
  updatedAt: Date
  type: TaskType
  status: AssignmentStatus
  task: TaskData
  work: AssignmentWork
  workOrder: Array<string>

  rowCount: number

  subTask: SubTask
  user: User
  job: Job
  organisation: Organisation
  projectId: string
  project?: Project
}

export interface AssignmentWork {
  [originalRowId: string]: AssignmentWorkRow
}
export interface AssignmentWorkRow {
  originalRowId: string
  userId: string
  trust: number
  assignmentId: string
  change?: RowChange
  consensus?: number
  tempData?: any
}
