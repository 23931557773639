import { ProjectMembership, ProjectMembershipType } from '../Project/Project'
import { User } from '../User/User'

export interface Organisation {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  members?: OrganisationMembership[]
}
export interface OrganisationMembership {
  id: string
  createdAt: Date
  updatedAt: Date
  organisation: Organisation
  organisationId: string
  user: User
  userId: string
  role: MembershipRole
  shareEmail: boolean
  shareFirstName: boolean
  shareLastName: boolean
  shareLocation: boolean
  projectMemberships?: ProjectMembership[]
}

export enum MembershipRole {
  Admin = 'admin',
  Member = 'member'
}
