import { Organisation, OrganisationMembership } from '../Organisation/Organisation'
import { User } from '../User/User'

export enum PermissionLevel {
  None = 'none',
  View = 'view',
  Edit = 'edit',
  Full = 'full'
}

//this is for comparing
export function PermissionLevelValue(level: PermissionLevel): number {
  if (level == PermissionLevel.View) {
    return 1
  }
  if (level == PermissionLevel.Edit) {
    return 2
  }
  if (level == PermissionLevel.Full) {
    return 3
  }
  return 0
}

export interface Project {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  members?: ProjectMembership[]
  archived: boolean
}

export interface ProjectMembership {
  id: string
  createdAt: Date
  updatedAt: Date
  organisation?: Organisation
  organisationId: string
  project?: Project
  projectId: string
  user?: User
  userId: string
  dashboardPermissions: PermissionLevel
  dataSetPermissions: PermissionLevel
  jobPermissions: PermissionLevel
  taskPermissions: PermissionLevel
  modelPermissions: PermissionLevel
  projectPermissions: PermissionLevel
}

export enum ProjectPermissionType {
  Dashboard = 'dashboardPermissions',
  DataSet = 'dataSetPermissions',
  Job = 'jobPermissions',
  Task = 'taskPermissions',
  Model = 'modelPermissions',
  Project = 'projectPermissions'
}

export enum ProjectMembershipType {
  Lead = 'lead',
  Member = 'member',
  Labeler = 'labeler',
  Custom = 'custom'
}
// Lead
// * dashboardPermissions: full
// * dataSetPermissions: full
// * jobsPermissions: full
// * taskPermissions: full
// * modelPermissions: full
// * projectPermissions: full

// Labeler
// * dashboardPermissions: none
// * dataSetPermissions: none
// * jobsPermissions: none
// * taskPermissions: view
// * modelPermissions: none
// * projectPermissions: none
// Custom
// * dashboardPermissions: full
// * dataSetPermissions: full
// * jobsPermissions: full
// * taskPermissions: full
// * modelPermissions: full
// * projectPermissions: view
export function ProjectMembershipTypeForMembership(
  membership: ProjectMembership
): ProjectMembershipType {
  if (
    membership.dashboardPermissions === PermissionLevel.Full &&
    membership.dataSetPermissions === PermissionLevel.Full &&
    membership.jobPermissions === PermissionLevel.Full &&
    membership.taskPermissions === PermissionLevel.Full &&
    membership.modelPermissions === PermissionLevel.Full &&
    membership.projectPermissions === PermissionLevel.Full
  ) {
    return ProjectMembershipType.Lead
  }

  // Member
  // * dashboardPermissions: edit
  // * dataSetPermissions: view
  // * jobsPermissions: full
  // * taskPermissions: view
  // * modelPermissions: view
  // * projectPermissions: view
  if (
    membership.dashboardPermissions === PermissionLevel.Edit &&
    membership.dataSetPermissions === PermissionLevel.View &&
    membership.jobPermissions === PermissionLevel.Full &&
    membership.taskPermissions === PermissionLevel.View &&
    membership.modelPermissions === PermissionLevel.View &&
    membership.projectPermissions === PermissionLevel.View
  ) {
    return ProjectMembershipType.Member
  }

  // * dashboardPermissions: none
  // * dataSetPermissions: none
  // * jobsPermissions: none
  // * taskPermissions: view
  // * modelPermissions: none
  // * projectPermissions: none

  if (
    membership.dashboardPermissions === PermissionLevel.None &&
    membership.dataSetPermissions === PermissionLevel.None &&
    membership.jobPermissions === PermissionLevel.None &&
    membership.taskPermissions === PermissionLevel.View &&
    membership.modelPermissions === PermissionLevel.None &&
    membership.projectPermissions === PermissionLevel.None
  ) {
    return ProjectMembershipType.Labeler
  }

  return ProjectMembershipType.Custom
}

export function SetProjectMembershipTypeForMembership(
  type: ProjectMembershipType,
  membership: ProjectMembership
): ProjectMembership {
  if (type === ProjectMembershipType.Lead) {
    membership.dashboardPermissions = PermissionLevel.Full
    membership.dataSetPermissions = PermissionLevel.Full
    membership.jobPermissions = PermissionLevel.Full
    membership.taskPermissions = PermissionLevel.Full
    membership.modelPermissions = PermissionLevel.Full
    membership.projectPermissions = PermissionLevel.Full
  }

  if (type === ProjectMembershipType.Member) {
    membership.dashboardPermissions = PermissionLevel.Edit
    membership.dataSetPermissions = PermissionLevel.View
    membership.jobPermissions = PermissionLevel.Full
    membership.taskPermissions = PermissionLevel.View
    membership.modelPermissions = PermissionLevel.View
    membership.projectPermissions = PermissionLevel.View
  }

  if (type === ProjectMembershipType.Labeler) {
    membership.dashboardPermissions = PermissionLevel.None
    membership.dataSetPermissions = PermissionLevel.None
    membership.jobPermissions = PermissionLevel.None
    membership.taskPermissions = PermissionLevel.View
    membership.modelPermissions = PermissionLevel.None
    membership.projectPermissions = PermissionLevel.None
  }

  return membership
}
