import { Entity } from '../models/NLP/NLP'

export function AreEntityArraysEqual(entities1?: Entity[], entities2?: Entity[]): boolean {
  if (!entities1 || !entities2) {
    return false
  }
  if (entities1.length !== entities2.length) {
    return false
  }
  if (entities1.length === 0 && entities2.length === 0) {
    return true
  }

  entities1.sort((a, b) => (a.startChar > b.startChar ? 1 : -1))
  entities2.sort((a, b) => (a.startChar > b.startChar ? 1 : -1))

  for (let i = 0; i < entities1.length; i++) {
    const e1 = entities1[i]
    const e2 = entities2[i]

    if (e1.name !== e2.name || e1.startChar !== e2.startChar || e1.endChar !== e2.endChar) {
      return false
    }
  }

  return true
}
