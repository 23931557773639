import { UserReference, User } from '../User/User'
import { Intent, Entities } from '../NLP/NLP'
import { Organisation } from '../../great-grey-lib'
import { Category, TaskCategory } from '../Task/TaskData'

const CurrentAPIVersion = '1.0'

export enum DataSetColumnType {
  Meta = 'meta',
  Text = 'text',
  Intent = 'intent',
  Entities = 'entities',
  Sentiment = 'sentiment',
  ImageURL = 'imageURL',
  AudioURL = 'audioURL',
  AudioCategories = 'audioCategories',
  AudioStartTime = 'audioStartTime',
  AudioEndTime = 'audioEndTime',
  AudioCategory = 'audioCategory',
  Duration = 'duration',
  AutomaticTranscription = 'automaticTranscription',
  Categories = 'categories',
  BoundingBoxes = 'boundingBoxes'
}

export enum DataSetType {
  Normal = 'normal',
  Job = 'job'
}

export interface DataSet {
  id: string
  type: DataSetType
  createdAt: Date
  updatedAt: Date
  organisation: Organisation
  name: string
  createdBy: User
  rowCount: number
  columnTypes: DataSetColumnType[]
  creationMethod: DataSetCreationMethod
  jobHistory?: DataSetDataHistory[]
  projectId: string
  rows: DataSetRow[]
}

export function isDataSet(item: any, skipId?: boolean): item is DataSet {
  if (!skipId && item === undefined && (item as DataSet).id === undefined) {
    return false
  }
  return (
    (item as DataSet).name !== undefined &&
    (item as DataSet).createdBy !== undefined &&
    (item as DataSet).rowCount !== undefined &&
    (item as DataSet).columnTypes !== undefined &&
    (item as DataSet).creationMethod !== undefined &&
    Object.values(DataSetCreationMethod).includes((item as DataSet).creationMethod)
  )
}

export enum DataSetCreationMethod {
  Upload = 'Upload',
  DataPoint = 'DataPoint',
  Job = 'Job',
  API = 'API'
}

export type MetaData = {[columnName:string]:string}

export interface DataSetRow {
  id: string
  meta?: MetaData
  text?: string
  intent?: Intent
  entities?: Entities
  sentiment?: number
  imageURL?: string
  audioURL?: string
  audioCategories?: TimelineCategory[]
  audioStartTime?: number
  audioEndTime?: number
  audioCategory?: TaskCategory
  parentRowId?: string // this is used to keep track of what row originally made  this row if it was created in a split.
  duration?: number
  automaticTranscription?: string // This is generated by ML transcription.

  categories?: CategoryData
  boundingBoxes?: BoundingBox[]
}
export type CategoryData = {
  [id: string]: Category // Id and category. The name is set durring the creation of the class and exported in the CSV as it's own column name.
}
export interface TimelineSegment {
  id: string
  startTime: number
  endTime: number
}

export interface TimelineCategory {
  id: string // Might be able to remove this.
  name: string
  segments: TimelineSegment[]
}

export interface DataSetDataHistory {
  createdAt: Date
  jobId: string
  jobName: string
}

export interface BoundingBox {
  id: string
  type: BoundingBoxType
  points: BoundingBoxRectangle
  category?: Category
  score?: number
}

export enum BoundingBoxType {
  Rectangle = 'rectangle',
  Polygon = 'polygone'
}
export interface BoundingBoxRectangle {
  x_relative_min: number
  y_relative_min: number
  x_relative_max: number
  y_relative_max: number
}

export function ColmunTypesForRows(rows: DataSetRow[]): DataSetColumnType[] {
  let types: DataSetColumnType[] = []

  let allTypes: DataSetColumnType[] = [
    DataSetColumnType.Meta,
    DataSetColumnType.Text,
    DataSetColumnType.Intent,
    DataSetColumnType.Entities,
    DataSetColumnType.Sentiment,
    DataSetColumnType.ImageURL,
    DataSetColumnType.AudioURL,
    DataSetColumnType.AudioCategories,
    DataSetColumnType.AudioStartTime,
    DataSetColumnType.AudioEndTime,
    DataSetColumnType.AudioEndTime,
    DataSetColumnType.Duration,
    DataSetColumnType.AudioCategory,
    DataSetColumnType.AutomaticTranscription,
    DataSetColumnType.Categories,
    DataSetColumnType.BoundingBoxes
  ]

  for (let i = 0; i < rows.length; i++) {
    const element = rows[i]
    for (let t = 0; t < allTypes.length; t++) {
      const type = allTypes[t]
      if (element[type] && typeof element[type] !== 'undefined' && !types.includes(type)) {
        types.push(type)
      }
    }
  }
  return types
}
