export interface GGDictionary<T> {
  [id: string]: T
}
export interface HasId {
  id: string
}

export interface SigningRequestResponse {
  signedRequest: string
  fileName: string
  url: string
}

export enum DataFileType {
  Job = 'Job',
  DataSet = 'DataSet',
  MLModel = 'MLModel'
}
