import { DataSetRow } from '../great-grey-lib'

export function NumberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export function Capitalize(s: string | undefined): string {
  if (!s || typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

interface HasAnIdInterface {
  id: string
}
export function ItemById(items: Array<HasAnIdInterface>, id: string): any | null | undefined {
  if (!items) {
    return null
  }
  for (let index = 0; index < items.length; index++) {
    if (items[index].id === id) {
      return items[index]
    }
  }
  return undefined
}

export type DictonaryType = { [type: string]: any }

export function MakeDictonaryArrayUnique(
  array: Array<DictonaryType>,
  uniqueKey: string
): Array<DictonaryType> {
  var existingKeys: Array<any> = []
  var filteredArray: Array<DictonaryType> = []
  for (let index = 0; index < array.length; index++) {
    const element = array[index]
    const key = element[uniqueKey]
    if (key && !existingKeys.includes(key)) {
      existingKeys.push(key)
      filteredArray.push(element)
    }
  }
  return filteredArray
}

export function ErrorPromise(messageOrError: string | Error): Promise<Error> {
  if (typeof messageOrError === 'string') {
    return Promise.reject(new Error(messageOrError))
  }
  return Promise.reject(messageOrError)
}

export function HoursMinutesSeconds(sec_num: number): string {
  // var sec_num = parseInt(milliseconds, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor((sec_num - hours * 3600) / 60)
  var seconds = Math.floor(sec_num - hours * 3600 - minutes * 60)

  // if (hours   < 10) {hours   = "0"+hours;}
  // if (minutes < 10) {minutes = "0"+minutes;}
  // if (seconds < 10) {seconds = "0"+seconds;}

  let hoursString = String(hours)
  let minutesString = String(minutes)
  let secondsString = String(seconds)

  if (hours > 0) {
    if (minutes < 10) {
      minutesString = '0' + minutes
    }
    if (seconds < 10) {
      secondsString = '0' + seconds
    }
    return hoursString + ':' + minutesString + ':' + secondsString
  } else if (minutes > 0) {
    if (seconds < 10) {
      secondsString = '0' + seconds
    }
    return minutesString + ':' + secondsString
  }

  return secondsString + 's'
}

export function TranscribeComparisonString(text: string): string {
  return text
    .replace(/[^\w\s]|_/g, '')
    .replace(/\s+/g, ' ')
    .toLowerCase()
}

// export async function AudioDurationForRow(row:DataSetRow):Promise<number|undefined>  {
//   if (typeof row.audioStartTime !== undefined && typeof row.audioEndTime !== undefined &&
//     row.audioStartTime !== undefined && row.audioEndTime !== undefined) {
//     return Promise.resolve(1*row.audioEndTime - 1*row.audioStartTime); // Multiply by 1 in case they are strings.
//   }
//   if (row.audioURL) {

//   }

//   return Promise.resolve(undefined);
// }
